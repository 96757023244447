import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/ListingHero'
import Routes from '../utilities/Routes'
import BreadCrumbComponent from '../components/BreadCrumbComponent'
import { graphql } from 'gatsby'
import ProductList from '../components/ProductCards'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    container: {
        padding: "20px",
        [theme.breakpoints.up('sm')]: {
            padding: "2rem"
        }
    }
}))

function Solutions({ data }) {
    const classes = useStyles()
    const breadCrumbs = [
        { id: "home", label: "Home", route: Routes.home },
        { id: "products", label: "Products" }
    ]
    const productList = !!data.cms.brsProducts ? data.cms.brsProducts : []
    return (
        <div>
            <Layout>
                <Hero
                    title='Products'
                    coverImageMobile={{ url: '/heroSampleMobile.png', alternativeText: "Products" }}
                    coverImageWeb={{ url: '/heroSample.png', alternativeText: "Products" }}
                />
                <BreadCrumbComponent crumbsList={breadCrumbs} />
                <div className='mainContainer'>
                    <div className={classes.container}>
                        <ProductList productList={productList} />
                    </div>
                </div>
            </Layout>
        </div>
    )
}


export const query = graphql`
query ProductsPageQuery {
  cms {
    brsProducts {
        id
        title
        slug
        showDetailPage
        shortDetails
        coverImage {
          url
          alternativeText
        }
    }
}
}
`

export default Solutions
